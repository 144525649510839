.templates-list {
    width: 100%;
    .zero-preview-template {
        height: 270px;
        border: 1px dashed #999;
        border-radius: 4px;
    }
    .filter-section {
        padding: 15px;
        padding-left: 18px;
    }
    .preview-template-card {
        background: #fff;
        border: none;
        height: 308px;
        width: 23%;
        margin: 0 1%;
        // padding: 15px;
        overflow: hidden;
        box-shadow: 0 0 10px 0px #e5e5e5;
        margin-bottom: 1.5rem;
        padding: 0;
        .img-hv-container {
            .image {
                opacity: 1;
                display: block;
                width: 100%;
                height: auto;
                transition: 0.5s ease;
                backface-visibility: hidden;
            }
            .buttons {
                transition: 0.5s ease;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                text-align: center;
                background-color: #2ab1ffad;
                button {
                    width: 120px;
                    color: #024a56;
                    background-color: white;
                    border: unset;
                    padding: 6px 8px;
                    box-shadow: 0 0 4px 0px #818181;
                    border-radius: 6px;
                    pointer-events: none;
                }
                .predefined-template-preview {
                    background-color: #024a56;
                    color: #fff;
                }
                .predefined-template-preview:hover {
                    background-color: #004a57;
                }
                button:not(.predefined-template-preview):hover {
                    background-color: rgb(230, 230, 230);
                }

                .predefined-template-delete {
                    background-color: #e38d4b;
                    color: #fff;
                    opacity: 1;
                }
                .predefined-template-delete:hover {
                    opacity: 0.8;
                }
            }
        }
        .tour-preview .image,
        .img-hv-container:hover .image {
            opacity: 0.3;
        }
        .tour-preview .buttons,
        .img-hv-container:hover .buttons {
            opacity: 1;
            button {
                pointer-events: all;
            }
        }
    }

    @media (max-width: 768px) {
        .preview-template-card {
            width: 98%;
            height: 498px;
        }
    }

    @media (min-width: 768px) and (max-width: 992px) {
        .preview-template-card {
            width: 48%;
            height: 398px;
        }
    }

    @media (min-width: 992px) and (max-width: 1180px) {
        .preview-template-card {
            width: 31%;
            height: 298px;
            margin: 0 1%;
            margin-bottom: 1.5rem;
        }
    }

    @media (min-width: 1180px) and (max-width: 1440px) {
        .preview-template-card {
            height: 288px;
        }
    }

    @media (max-width: 992px) {
        .preview-template-card {
            margin-bottom: 1rem;
        }
    }
    @media (max-width: 660px) {
        .preview-template-card {
            width: 94% !important;
        }
    }
    .form-select:focus,
    .form-select {
        border: unset;
        border-bottom: 1px solid #ced4da;
        border-radius: unset;
        box-shadow: unset;
        background-color: transparent;
    }
}
