// Palette variables
$primaryColor: #444;
$secondaryColor: #ddd;
$tertiaryColor: #804f7b;
$quaternaryColor: #d278c9;

@import "grapesjs/src/styles/scss/main.scss";

// library imports
@import "./partials/variables";
@import "./partials/scrollbar";
@import "~bootstrap/scss/bootstrap";
@import "./developer.scss";

@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap);
body:not(.overflow-auto) {
    font-family: "Rubik", sans-serif !important;
    -webkit-font-smoothing: antialiased;
}
