/* We can remove the border we've set at the beginning */
#gjs {
    border: none;
}

.tab-content {
    height: 100% !important;
}

.blockbuilder-branding {
    display: none !important;
}

.u_body {
    background-color: white !important;
}

/* Reset some default styling */
.grapesJS-panel-layout {
    .gjs-block {
        // width: auto;
        height: auto;
        min-height: auto;
    }

    .editor-row {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
        height: 100%;
    }

    .editor-canvas {
        flex-grow: 1;
    }

    .card-slider-icon {
        font-size: 108px;
        margin-left: -2px;
    }

    .gjs-pn-panel#gjs-pn-views-container,
    .gjs-pn-panel.gjs-pn-views-container {
        height: calc(100% - 150px);
    }
}

.template-edit {
    position: relative;

    .buttons-container {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 150px;
        width: 15%;

        button:not(.btn-info) {
            width: 126px;
        }
    }

    .page-loading {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.save-confirm-modal,
.template-cancel-confirm {
    .warning-icon {
        color: #f8bb86;
        font-size: 4.5rem;
    }

    .buttons-container {
        button {
            margin: 0 4px;
            width: 90px;
        }
    }
}

.save-confirm-modal {
    .save-text {
        color: #ff9c45;
        font-size: 1.6rem;
    }
}

.tour-modal {
    .tour-content {
        position: relative;
    }

    .close-icon {
        display: flex;
        position: absolute;
        right: 15px;
        background: white;
        border-radius: 50%;
        padding: 5px;
    }

    .modal-content {
        background-color: transparent !important;
        border: unset !important;
    }

    .modal-dialog {
        width: 600px;
    }

    .tour-content {
        background-color: #5645488a;
        background-image: linear-gradient(#51414485, #9e878a45);
    }
}

.download-progress {
    width: 100px;
    height: 10px;
}

.gjs-off-prv.fa-eye-slash {
    font-size: 58px;
}

.event-button {
    width: 150px;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}