.app-layout {
    display: flex;
    flex-direction: column;
    .header-section {
        flex: 0 1 auto;
        // box-shadow: 0px 0px 7px 0px #b9b9b9;
        border-bottom: 1px solid #aaaaaa;
    }
    .body-section {
        flex: 1 1 auto;
        // padding: 10px;
    }
    .footer-section {
        flex: 0 1 0px;
    }
}
