.permission-denied-page {
    height: 100%;
    .oops {
        font-size: 54px;
        font-weight: 300;
        font-family: Rubik sans-serif;
    }
    .denied {
        font-weight: 400;
    }
    .back-to-home {
        background-color: initial;
        border: 2px solid #030303;
        border-radius: 4px;
        color: gray;
        font-size: 18px;
        font-weight: 600;
        padding: 8px 20px;
    }
}
