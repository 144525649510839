.template-list-container {
    --tab-height: 56px;
    --page-height: calc(100% - 113px);
    // width: 100%;

    .pages-section,
    .tabs-section {
        height: var(--tab-height);
        width: 100%;
    }

    .tabs-section {
        // .large-screen-width {
        //     border-bottom: 1px solid #aaaaaa;
        // }
        .tab {
            padding: 10px 14px;
            border-radius: 10px 10px 0 0;
            font-weight: 500;
            a {
                color: inherit;
                text-decoration: none;
            }
        }
        .tab.active {
            border-bottom: 3px solid #2da9f1;
            a {
                color: #2da9f1;
            }
        }
    }
    .outlet-section {
        // padding: 15px;
        height: var(--page-height);
    }
    .create-new {
        background-color: #65af76;
        // border-color: #55cb71;
        border: unset;
        // font-weight: 500;
    }
}
