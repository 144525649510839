.login-container {
    .spinner-border {
        width: 1.25rem;
        border-width: 0.15rem;
        height: 1.25rem;
    }
    .card {
        box-shadow: 0 0 7px 2px #efefef;
        border: unset;
        width: 526px;
    }
    @media (max-width: 526px) {
        .card {
            width: 98%;
        }
    }
}
