.saved-template-container {
    width: 100%;
    padding: 0 15px;
    .templates-section {
        .table-responsive {
            border: 1px solid #979797;
            background: white;
        }
        .table-responsive.min-height {
            min-height: 450px;
        }
        .table {
            background-color: white;
            margin-bottom: 0;
            thead {
                tr {
                    border-bottom: 1px solid #979797;
                }
            }
            th,
            td {
                padding: 1rem;
            }
        }
        .no-data-found {
            height: 200px;
        }
        .action-section {
            .btn-preview,
            .btn-analytics,
            .btn-schedule,
            .btn-active,
            .btn-clone {
                border: unset;
                padding: 3px 8px;
                border-radius: 4px;
                margin-right: 8px;
            }
            .btn-preview,
            .btn-clone {
                background-color: transparent;
                padding: 4px 8px;
                margin-right: unset;
            }
            .btn-analytics {
                border: 1px solid #979797;
                color: #606060;
                background-color: white;
            }
            .btn-active {
                border: 1px solid #2da9f1;
                color: #2da9f1;
                background-color: white;
                width: 90px;
            }
            .btn-active.active {
                background-color: #2da9f1;
                color: white;
                border: unset;
            }
            .btn-active.success {
                background-color: #74db74;
                color: white;
            }
            .btn-active.publish {
                border: 1px solid #2f9df5;
                color: #2f9df5;
                background-color: white;
            }
            .btn-edit,
            .btn-delete {
                background-color: transparent;
                border: unset;
                .material-icons-outlined {
                    font-size: 28px !important;
                }
            }
            .btn-delete {
                margin-right: 8px;
            }
            .btn-active:disabled {
                pointer-events: none;
            }
            .btn-schedule {
                background-color: white;
                border: 1px solid #707070;
                color: #707070;
            }
            .btn-schedule.active {
                border: 1px solid #55cb71;
                color: #55cb71;
                background-color: white;
            }
        }
        .template-name,
        .template-description {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            padding: 0 10px;
            overflow: hidden;
            max-width: 450px;
        }
    }
}

.template-delete-confirm {
    .warning-icon {
        color: #f8bb86;
        font-size: 4.5rem;
    }
    .buttons-container {
        button {
            margin: 0 4px;
            width: 90px;
        }
    }
}
.ant-picker-dropdown {
    z-index: 1056;
}
.w-80 {
    width: 80%;
}
