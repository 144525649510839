.clickable {
  cursor: pointer;
}

.large-screen-width {
  width: calc(85rem + 72px);
}

@media (max-width: 768px) {
  .large-screen-width {
    width: 100%;
  }
}
