.preview-image-modal {
    .image-container {
        position: relative;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        padding: 20px;
        border-radius: 10px;
        border: 2px dashed #555;
        color: #444;
        cursor: pointer;
        transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
    }

    .image-container:hover {
        background: #eee;
        border-color: #111;
    }

    .image-container:hover .image-title {
        color: #222;
    }

    .image-title {
        color: #444;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        transition: color 0.2s ease-in-out;
    }

    input[type="file"] {
        opacity: 0;
        position: absolute;
    }
    .uploaded-image {
        max-width: 100%;
    }
}
