.header-container {
    .brand-logo {
        max-width: 100%;
        max-height: 100%;
        width: 225px;
        height: 5rem;
        object-fit: contain;
    }

    .brand-logo-mobile {
        width: 28px;
    }

    .left-section {
        // color: white;
        .brand-name {
            font-size: 20px;
            font-weight: 500;
            text-decoration: none;
            color: black;
        }
        .brand-name:hover {
            color: #0d6efd;
            cursor: pointer;
        }
    }
    .right-section {
        // color: white;
        .icons {
            .material-icons {
                font-size: 30px;
            }
        }
        .profile-container {
            font-size: 0.9rem;
            img {
                margin-right: 0.25rem;
            }
            .chevron-down {
                font-size: 1.25rem;
            }
        }
        .profile-container:hover {
            color: #0d6efd;
        }
    }
    .pages-section {
        height: 5rem;
        .page {
            padding: 27px 20px !important;
            // border-radius: 14px 14px 0 0;
            font-weight: 500;
            a {
                color: #a5a5a5;
                text-decoration: none;
                div {
                    padding-top: 3px;
                }
            }
        }
        .page.active {
            background-color: #2da9f1;
            a {
                color: #fff;
            }
        }
    }

    .logout-button {
        width: 28px;
    }

    @media (max-width: 375px) {
        .page {
            padding: 10px !important;
        }
    }
}
